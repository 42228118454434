import '../../style/index.less';
import './index.less';
// style dependencies
import '../../button/style';
import '../../checkbox/style';
import '../../dropdown/style';
import '../../empty/style';
import '../../input/style';
import '../../menu/style';
import '../../pagination/style';
// deps-lint-skip: form
